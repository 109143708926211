import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import useWindowDimensions from '../Hooks/useWindowDimensions';

const ProgressBar = props => {
  
  const { windowWidth } = useWindowDimensions();

  let activeColor           = '#327BBC';
  let passiveColor          = '#343333';

  let section1style         = { color: activeColor, fontWeight: '700' };
  let section2style         = { color: passiveColor, fontWeight: '400' };
  let section3style         = { color: passiveColor, fontWeight: '400' };
  let section4style         = { color: passiveColor, fontWeight: '400' };
  let section5style         = { color: passiveColor, fontWeight: '400' };
  let section6style         = { color: passiveColor, fontWeight: '400' };

  const [section1, setSection1] = React.useState({ color: activeColor, fontWeight: '700' });
  const [section2, setSection2] = React.useState({ color: passiveColor, fontWeight: '400' });
  const [section3, setSection3] = React.useState({ color: passiveColor, fontWeight: '400' });
  const [section4, setSection4] = React.useState({ color: passiveColor, fontWeight: '400' });
  const [section5, setSection5] = React.useState({ color: passiveColor, fontWeight: '400' });
  const [section6, setSection6] = React.useState({ color: passiveColor, fontWeight: '400' });
  
  let progressWidth         = '25%';
  if(window.innerWidth < 650) {
    progressWidth           = '100%';
  }

  let progress1             = 0;
  let progress2             = 0;
  let progress3             = 0;
  let progress4             = 0;
  let progress5             = 0;
  let progress6             = 0;

  if(props.currentProgress <= 4) {
    progress1               = ((props.currentProgress + 1) / 5) * 100;
    section1style           = { color: activeColor,  fontWeight: '700' };
    section2style           = { color: passiveColor, fontWeight: '400' };
    section3style           = { color: passiveColor, fontWeight: '400' };
    section4style           = { color: passiveColor, fontWeight: '400' };
    section5style           = { color: passiveColor, fontWeight: '400' };
    section6style           = { color: passiveColor, fontWeight: '400' };
  } else {
    progress1               = 100;
    section1style           = { color: passiveColor, fontWeight: '400' };
  }

  if(props.currentProgress > 4 && props.currentProgress <= 9) {
    progress2               = (((props.currentProgress + 1) - 5) / 5) * 100;
    section1style           = { color: passiveColor, fontWeight: '400' };
    section2style           = { color: activeColor,  fontWeight: '700' };
    section3style           = { color: passiveColor, fontWeight: '400' };
    section4style           = { color: passiveColor, fontWeight: '400' };
    section5style           = { color: passiveColor, fontWeight: '400' };
    section6style           = { color: passiveColor, fontWeight: '400' };
  } else {
    if(props.currentProgress < 5) {
      progress2             = 0;
    } else {
      progress2             = 100;
    }
    section2style           = { color: passiveColor };
  }

  if(props.currentProgress > 9 && props.currentProgress <= 14) {
    progress3               = (((props.currentProgress + 1) - 10) / 5) * 100;
    section1style           = { color: passiveColor, fontWeight: '400' };
    section2style           = { color: passiveColor, fontWeight: '400' };
    section3style           = { color: activeColor,  fontWeight: '700' };
    section4style           = { color: passiveColor, fontWeight: '400' };
    section5style           = { color: passiveColor, fontWeight: '400' };
    section6style           = { color: passiveColor, fontWeight: '400' };
  } else {
    if(props.currentProgress < 10) {
      progress3             = 0;
    } else {
      progress3             = 100;
    }
    section3style           = { color: passiveColor, fontWeight: '400' };
  }

  if(props.currentProgress > 14 && props.currentProgress <= 19) {
    progress4               = (((props.currentProgress + 1) - 15) / 5) * 100;
    section1style           = { color: passiveColor, fontWeight: '400' };
    section2style           = { color: passiveColor, fontWeight: '400' };
    section3style           = { color: passiveColor, fontWeight: '400' };
    section4style           = { color: activeColor, fontWeight: '700' };
    section5style           = { color: activeColor, fontWeight: '700' };
    section6style           = { color: activeColor, fontWeight: '700' };
  } else {
    if(props.currentProgress < 15) {
      progress4             = 0;
    } else {
      progress4             = 100;
    }
    section4style           = { color: passiveColor };
  }

  // if(props.currentProgress >= 9 && props.currentProgress < 12) {
  //   progress5               = (((props.currentProgress + 1) - 9) / 5) * 100;
  //   section1style           = { color: passiveColor, fontWeight: '400' };
  //   section2style           = { color: passiveColor, fontWeight: '400' };
  //   section3style           = { color: passiveColor, fontWeight: '400' };
  //   section4style           = { color: passiveColor, fontWeight: '400' };
  //   section5style           = { color: activeColor, fontWeight: '700' };
  //   section6style           = { color: passiveColor, fontWeight: '400' };
  // } else {
  //   if(props.currentProgress < 20) {
  //     progress5             = 0;
  //   } else {
  //     progress5             = 100;
  //   }
  //   section5style           = { color: passiveColor };
  // }

  // if(props.currentProgress >= 12 && props.currentProgress < 14) {
  //   progress6               = (((props.currentProgress + 1) - 12) / 5) * 100;
  //   section1style           = { color: passiveColor, fontWeight: '400' };
  //   section2style           = { color: passiveColor, fontWeight: '400' };
  //   section3style           = { color: passiveColor, fontWeight: '400' };
  //   section4style           = { color: passiveColor, fontWeight: '400' };
  //   section5style           = { color: passiveColor, fontWeight: '400' };
  //   section6style           = { color: activeColor, fontWeight: '700' };
  // } else {
  //   if(props.currentProgress < 13) {
  //     progress6             = 0;
  //   } else {
  //     progress6             = 100;
  //   }
  //   section6style           = { color: passiveColor };
  // }

  let progressPercentage = Math.round(((props?.currentProgress + 1) / 20) * 100);

  // calculate percentage per section per question 
  const calcPercentPerQuestion = () => {
    let questionsPerSection = props?.questionsPerSection;
    let sections = props?.questionsPerSection?.length;
    // 100% divided by number of sections 
    let eachSection = parseFloat((100 / sections).toFixed(2));
    // console.log(eachSection);
    let progress = 0;
    let progressPerQuestionArr = [];
    for (let i = 0; i < sections; i++) {
      progress = eachSection / questionsPerSection[i];
      // console.log(progress);
      for(let j = 0; j < questionsPerSection[i]; j++) {
        progressPerQuestionArr.push(parseFloat(progress.toFixed(2)));
      }
    }

    let accumulator = 0
    progressPerQuestionArr?.forEach((value, index) => {
      if(index <= props?.currentProgress) { accumulator += value; }
      return accumulator;
    })
    // console.log("accumulator", accumulator);
    return Math.round(accumulator);
  }
  const progressPercent = calcPercentPerQuestion();
  console.log(progressPercent);


  return (
    <div>
      {/* <p className="progress-percent-text" variant="determinate" style={{
        marginLeft: `calc(${progressPercent}% - 3ch)`
      }}>{`${progressPercentage}%`}</p> */}
      
      {/* <p className="progress-percent-text" variant="determinate" style={{
        marginLeft: `calc(${progressPercent}% - 2ch)`
      }}>{`${progressPercentage}%`}</p> */}

      <div className="progress-bar">                
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress1} /></div>
          <div className="section-title" style={section1style}>{windowWidth <= 550 ? "STRONGLY CONFIDENT" : <>STRONGLY<br />CONFIDENT</>}</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress2} /></div>
          <div className="section-title" style={section2style}>{windowWidth <= 550 ? "PRETTY CONFIDENT" : <>PRETTY<br />CONFIDENT</>}</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress3} /></div>
          <div className="section-title" style={section3style}>QUESTIONING</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress4} /></div>
          <div className="section-title" style={section4style}>DOUBTING</div>
        </div>
        {/* <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress5} /></div>
          <div className="section-title" style={section5style}>VOLUNTEERS</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress6} /></div>
          <div className="section-title" style={section6style}>COMMUNITY</div>
        </div> */}
      </div>

    </div>
  );
}
export default ProgressBar;
