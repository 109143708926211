import { useState, useRef, useEffect } from 'react';

import { Link, useSearchParams } from "react-router-dom";

import useWindowDimensions from '../Hooks/useWindowDimensions';
import VideoThumbnail from '../Assets/Images/video-thumb-cropped.png';
import PlayBtn from '../Assets/Images/play-button.png';
import PlayBtnHover from '../Assets/Images/play-button.png';
import Clock from '../Assets/Images/clock.svg';
import { AccessTime } from '@mui/icons-material';

import '../App.scss';

const Start = props => {
  const { windowWidth }                   = useWindowDimensions();
  const [isLoading, setIsLoading]         = useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);

  searchParams.get("leader");
  const vidRef = useRef(null);
  
  
  console.log("searchParams", searchParams.get("leader"));
  console.log("searchParams", searchParams.get("user"));
  console.log("searchParams", searchParams);

  // useEffect(() => {
	// 	document.body.style.backgroundColor = "#343333";
	// },[]);

  const assessmentLink = () => {
    if(!searchParams) {
      // return "/before-you-start";
      return "/assessment";
    } else {
      // return "/before-you-start?" + searchParams;
      return "/assessment?" + searchParams;
    }
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    // vidRef.current.play();
    // setPlayingVideo(true);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
    if(windowWidth > 830) {
      // document.getElementsByClassName("intro")[0].style.opacity = "0";
      document.getElementsByClassName("intro")[0].style.width = "0%";
      document.getElementsByClassName("intro")[0].style.padding = "0";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      document.getElementsByClassName("video")[0].style.width = "100%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#22223B";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "41%";
      document.getElementsByClassName("intro")[0].style.padding = "0 6%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "65%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#22223B";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "41%";
      document.getElementsByClassName("intro")[0].style.padding = "0 6%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "65%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#22223B";
    }
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img className="thumbnail" src={VideoThumbnail} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <img className="thumbnail" src={VideoThumbnail} />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img className="playbtn" src={PlayBtn} onClick={(e) => playVideo(e)} alt="play button icon" />
    }
  }

  const vidsrcURL = "";
  
  return (
    <div className="container start">
      <div className="video">
        <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
          <source
            src="https://40parables-assets.s3.amazonaws.com/bleat-are-you-doubting-your-faith.mp4"
            type="video/mp4"
            />
        </video>
        {showThumbnail()}
        {showPlayButton()}
      </div>
      <div className="intro">
        {windowWidth <= 830 ? <h1>Wrestling with questions about your faith?</h1> :
        <><h1>Wrestling with questions about your faith?</h1></>}
        <h2>
        Doubt often gets a bad reputation in the Christian world. But it shouldn't. At some point in our lives, we all question our faith and even God. Where are you on your faith journey?
        </h2>
        <div className="completion">
          {/* <Link to={assessmentLink()} className="cta"> */}
          {/* <Link to="/before-you-start" className="cta">Get Started</Link> */}
          <Link to={assessmentLink()} className="cta">Get Started</Link>
          <div className='time-estimate'>
            {/* <img className='clock-icon' src={Clock} alt="" /> <span>3 min</span> */}
            <AccessTime className='clock-icon' alt="" /> <span>3 min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
  );
}
export default Start;