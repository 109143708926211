import { useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const PreAssessment = () => {

  const [searchParams, setSearchParams]   = useSearchParams();
  let navigate                            = useNavigate();

  // useEffect(() => {
	// 	document.body.style.backgroundColor = "#343333";
	// },[]);
  
  const assessmentLink = () => {
    if(!searchParams) {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  return (
    <div className="pre-assessment">
      <h1>Before You Start</h1>
      <p className="pre-assessment-subheader">For each of the following 21 church benchmarks, choose one of these three responses that accurately reflects your church's current situation.</p>
      <div className="explanation">
        <div className="marker red"></div><p>The benchmark is not in position to activate the Great Commission or multiply Kingdom impact. It is potentially broken or barely functioning to these standards, and demands attention.</p>
      </div>
      <div className="explanation">
        <div className="marker yellow"></div><p>The benchmark affects Great Commission activation and multiplies Kingdom impact but has room for improvement. It is not at its top level of effectiveness, and needs further action to become optimal.</p>
      </div>
      <div className="explanation">
        <div className="marker green"></div><p>The benchmark activates the Great Commission and multiplies Kingdom impact. It is effective and healthy, but might need slight improvement. Ensure it has the resources and attention it needs.</p>
      </div>
      <Link to={assessmentLink()} className="cta-link">
        <Button className="cta">LET'S BEGIN</Button>
      </Link>
      
    </div>
  )
}


export default PreAssessment;
