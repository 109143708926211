import React from "react";
import bookImg from "../Assets/Images/book.png";
import amazon from "../Assets/Images/amazon.png";
import amazones from "../Assets/Images/amazones.png";
import brucemiller from "../Assets/Images/brucemiller.png";
import audible from "../Assets/Images/audible.png";
import barnesNoble from "../Assets/Images/barnes&noble.png";

const BookSection = () => {
  return (
    <div className="book-section-container">
      <section className="book-section">
        <div className="book-image">
          <img src={bookImg} alt="The 7 Big Questions book by Bruce B. Miller" />
        </div>
        <div className="book-details">
          <h3>BUY THE BOOK</h3>
          <p>Wherever you are in your faith journey, you can take the next step. Get started on digging deeper into your questions.</p>
          <p className="available-now">Available now:</p>
          <div className="book-links">

            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.amazon.com/Big-Questions-Searching-Truth-Purpose/dp/1683160207/ref=sr_1_1?keywords=7+big+questions+bruce+miller&qid=1664204673&sprefix=7+big+questions%2Caps%2C99&sr=8-1"
              title="The 7 Big Questions book by Bruce B. Miller - amazon.com">
              <img className="shop-logo amazon" src={amazon} alt="The 7 Big Questions book by Bruce B. Miller on amazon.com" /></a>

            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.amazon.com/Big-Questions-Searching-Truth-Purpose/dp/B0BKC3NSL1/"
              title="The 7 Big Questions audio book Bruce B. Miller - audible.com">
              <img className="shop-logo audible" src={audible} alt="The 7 Big Questions audio book by Bruce B. Miller on audible.com" /></a>

            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.amazon.com/Las-Grandes-Preguntas-Prop%C3%B3sito-Spanish/dp/1683160258/ref=sr_1_1?crid=D9S7S14MRJPH&keywords=las+preguntas+bruce+miller&qid=1666567763&sprefix=las+preguntas%2Caps%2C106&sr=8-1"
              title="The 7 Big Questions book by Bruce B. Miller - amazon.es">
              <img className="shop-logo amazones" src={amazones} alt="The 7 Big Questions book by Bruce B. Miller on amazon.es" /></a>

            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.barnesandnoble.com/w/the-7-big-questions-bruce-b-miller/1142295168?ean=9781683160205"
              title="The 7 Big Questions book by Bruce B. Miller - barnesandnoble.com">
              <img className="shop-logo barnesNoble" src={barnesNoble} alt="The 7 Big Questions book by Bruce B. Miller on Barnes & Noble" /></a>

            <a className="book-link" rel="noreferrer" target="_blank" href="http://www.brucebmiller.com/books"
              title="The 7 Big Questions book by Bruce B. Miller - brucebmiller.com">
              <img className="shop-logo brucemiller" src={brucemiller} alt="The 7 Big Questions book by Bruce B. Miller on brucebmiller.com" /></a>

          </div>
        </div>
      </section>
    </div>
  )
}


export default BookSection;
